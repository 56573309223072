import React, { useMemo } from 'react'
import items from "./data";
import "./DrawerContent.css";
import { AiOutlineLine } from 'react-icons/ai'
interface DrawerContentProps {
  itemId: number
}
export default function DrawerContent(props: DrawerContentProps) {

  const item = useMemo(() => {
    return items.find(i => i.id === props.itemId)
  }, [props.itemId])


  if (!item) {
    return <div>no item found</div>
  }

  return (
    <div className='drawer-content-container'>
      <AiOutlineLine color='grey' size={40} style={{ width: '100%' }} />
      <img src={item.img} alt={item.title} className="drawer-content-image" />

      <div>
        <h4 className=''>{item.title}</h4>
      </div>
      <h4 className="price">${item.price}</h4>
      <p className="item-text">{item.desc}</p>

    </div>
  )
}
