import React, { useCallback, useMemo, useState } from "react";
import Menu from "./Menu";
import Categories from "./Categories";
import items from "./data";
import logo from './haji-logo.avif';
import { Drawer, SwipeableDrawer } from "@mui/material";
import DrawerContent from "./DrawerContent";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
// import './fonts/KhebratMusamimRegular'

const App = () => {
  const [menuItems, setMenuItems] = useState(items);
  const [activeCategory, setActiveCategory] = useState("الكل");
  const [activeItemId, setActiveItemId] = useState<number | undefined>()

  const allCategories = useMemo(() => {
    const categories = new Set(items.map(item => item.category))
    return ["الكل", ...Array.from(categories)]
  }, [])


  const filterItems = useCallback((category: string) => {
    setActiveCategory(category);
    if (category === "الكل") {
      setMenuItems(items);
      return;
    }
    const newItems = items.filter((item) => item.category === category);
    setMenuItems(newItems);
  }, [])

  const selectItemFocus = useCallback((itemId: number) => {
    setActiveItemId(itemId)
  }, [])

  const onSearchChange = (query: string) => {
    if (!query) {
      filterItems(activeCategory)
      return
    }
    const filteredItems = items.filter(i => i.tags?.find(t => t.startsWith(query)))
    setMenuItems(filteredItems)
  }

  return (
    <main>
      <section className="menu section">

        <div className="title">
          <h2>قائمة الطعام</h2>
          <div className="underline"></div>
          <img src={logo} alt="logo" className="logo" style={{ width: 150 }} />
          <Box
            sx={{
              maxWidth: '600px',
              width: '100%',
              // '& .MuiTextField-root': { m: 1, maxWidth: '25ch' },
            }}
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <div>
              <TextField
                id="outlined-search"
                label="ابحث"
                type="search"
                fullWidth
                onChange={(e) => onSearchChange(e.target.value)}
              />
            </div>
          </Box>
        </div>
        <Categories
          categories={allCategories}
          activeCategory={activeCategory}
          filterItems={filterItems}
        />
        <Menu
          items={menuItems}
          onSelectItem={selectItemFocus}
        />
      </section>

      <SwipeableDrawer
        // PaperProps={{
        //   sx: {
        //     height: '70%',
        //     // top: 64,
        //   }
        // }}
        anchor={"bottom"}
        open={!!activeItemId}
        onClose={() => {
          setActiveItemId(undefined)
        }}
        onOpen={() => {
          setActiveItemId(undefined)
        }}
      >
        <DrawerContent
          itemId={activeItemId!}
        />
      </SwipeableDrawer>
    </main >
  );
};

export default App;
