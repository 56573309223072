import React from "react";
import { Menu as TMenu } from "./data";


interface MenuProps {
  items: TMenu[];
  onSelectItem: (itemId: number) => void;
}
export default function Menu({ items, ...props }: MenuProps) {
  return (
    <div className="section-center">
      {items.map((item) => {
        const { id, title, img, desc, price } = item;
        return (
          <article key={id} className="menu-item">
            <div style={{ width: "90%" }}>

              <header onClick={() => props.onSelectItem(id)}>
                <h4>{title}</h4>
              </header>
              <h4 className="price">${price}</h4>
              <div className="">
                <div className="item-info">

                  <p className="item-text">{desc}</p>
                </div>
              </div>
            </div>

            <img src={img} alt={title} className="photo" />
          </article>
        );
      })}
    </div>
  );
};