
export type Menu = {
  id: number;
  title: string;
  category: string;
  price: number;
  img: string;
  desc: string;
  tags?: string[];
}

const menu: Menu[] = [
  {
    id: 1,
    title: 'بانكيك باللبن',
    category: 'الفطور',
    price: 15.99,
    img: './images/item-1.jpeg',
    desc: `لوح التزلج فام موالفة  لمبة إديسون يوتشي كروسيفيكس ميكرو.`,
    tags: ['بانكيك', 'فطور']
  },
  {
    id: 2,
    title: 'غداء لشخصين',
    category: 'الغداء',
    price: 13.99,
    img: './images/item-2.jpeg',
    desc: `لوح التزلج فام موالفة السيميائية الأصيلة. ليفت ليفت لايف ، لمب ميكرو.`,
  },
  {
    id: 3,
    title: 'ميلك شيك جودزيلا',
    category: 'ميلك شيك',
    price: 6.99,
    img: './images/item-3.jpeg',
    desc: `الأصيلة. ليفت ليفت لايف ، لمبة إديسون يوتشي كروسيفيكس ميكرو.`,
    tags: ['ميلك شيك']

  },
  {
    id: 4,
    title: 'فطور كونتري ديلايت',
    category: 'الفطور',
    price: 20.99,
    img: './images/item-4.jpeg',
    desc: `لوح التزلج فام موالفة السيميائية إديسون يوتشي كروسيفيكس ميكرو.`,
  },
  {
    id: 5,
    title: 'هجوم البيض',
    category: 'الغداء',
    price: 22.99,
    img: './images/item-5.jpeg',
    desc: `لوح التزلجلمبة إديسون يوتشي كروسيفيكس ميكرو.`,
    tags: ['بيض']
  },
  {
    id: 6,
    title: 'اوريو دريم',
    category: 'ميلك شيك',
    price: 18.99,
    img: './images/item-6.jpeg',
    desc: `. ليفت ليفت لايف ، لمبة إديسون يوتشي كروسيفيكس ميكرو.`,
    tags: ['اوريو']
  },
  {
    id: 7,
    title: 'فطور اللحم المقدد',
    category: 'الفطور',
    price: 8.99,
    img: './images/item-7.jpeg',
    desc: `لوح التزلج فام موالفبة إديسون يوتشي كروسيفيكس ميكرو.`
  },
  {
    id: 8,
    title: 'الكلاسيكية الأمريكية',
    category: 'الغداء',
    price: 12.99,
    img: './images/item-8.jpeg',
    desc: `لوح التزلج فام موالفة السيميائية الأميكرو.`,
  },
  {
    id: 9,
    title: 'ميلك شيك الصديق',
    category: 'ميلك شيك',
    price: 16.99,
    img: './images/item-9.jpeg',
    desc: `لوح التزلج فام موالفة السيميائية الأصيلة. ميكرو.`,
    tags: ['ميلك شيك']
  },
];
export default menu;
