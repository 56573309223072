import React from "react";

interface CategoriesProps {
  categories: string[];
  filterItems: (category: string) => void;
  activeCategory: string;
}

const Categories = ({ categories, filterItems, activeCategory }: CategoriesProps) => {
  return (
    <div className="btn-container">
      {categories.map((category, index) => {
        return (
          <button
            type="button"
            className={`${activeCategory === category ? "filter-btn active" : "filter-btn"
              }`}
            key={index}
            onClick={() => filterItems(category)}
          >
            {category}
          </button>
        );
      })}
    </div>
  );
};

export default Categories;
